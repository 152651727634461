<template>
    <!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Cart</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- Cart section start -->
    <div class="cy_cart_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="cy_cart_box woocommerce">
                        <table class="shop_table shop_table_responsive cart">
                            <thead>
                                <tr>
                                    <th class="product-thumbnail">Image</th>
                                    <th class="product-name">Name</th>
                                    <th class="product-price">Price</th>
                                    <th class="product-price">Qty</th>
                                    <th class="product-remove">Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(cart, index) in $store.getters.cart" :key="cart.id">
                                    <td class="product-thumbnail">
                                        <a>
                                            <img v-if="cart.images[0]" :src="cart.images[0]" alt="cart" class="img-fluid">
                                            <img v-else src="http://via.placeholder.com/68x68" alt="cart" class="img-fluid">
                                        </a>
                                    </td>
                                    <td class="product-name">
                                        {{cart.ProductName}}<br/>
                                        Size : {{ cart.size.name }} <br/>
                                        Color : {{ cart.color.name }}
                                    </td>
                                    <td class="product-price">{{cart.ProductCurrency}}{{ cart.Custprice }}</td>
                                    <td class="product-price"><b>{{ cart.quantity }}</b> </td>
                                    <td class="product-remove"><a style="cursor:pointer;" @click="removeProduct(index)"><i class="fa fa-trash-o" aria-hidden="true"></i></a></td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="cy_cart_sidebar">
                        <div class="cart-collaterals">
                            <div class="cart_totals">
                                <h2>Cart Total</h2>
                                <table class="shop_table shop_table_responsive">
                                    <!-- <tbody>
                                        <tr class="cart-subtotal">
                                            <td>Subtotal</td>
                                            <td>$7500</td>
                                        </tr>
                                        <tr class="cart-shipping">
                                            <td>Shipping</td>
                                            <td>$350</td>
                                        </tr>
                                        <tr class="cart-tax">
                                            <td>Value Added Tax</td>
                                            <td>$500</td>
                                        </tr>
                                    </tbody> -->
                                    <tfoot>
                                        <tr class="order-total">
                                            <th>Total</th>
                                            <th>{{ $store.getters.totalAmount }}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div class="wc-proceed-to-checkout">
                                <!-- <button @click="test()" class="cy_button">Proceed To Checkout</button> -->
                                <router-link to="/checkout" class="cy_button">Proceed To Checkout</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />
    </div>
</template>

<script>
    export default {
		name: 'cart-page',
		data(){
            return {
                
            }
		},

		created() {

		},

		mounted() {  
            
		},

		methods: {
            removeProduct(index){
                this.$store.dispatch('removeProduct', index)
            },

            test(){
                this.$refs.alert.showAlert(
                    'info',
                    'Under Development',
                    'Info'
                )
            }
		},
        computed: {
            currentUser() {
                return this.$store.getters.currentUser;
            },
        }
    }
</script>