<template>
	<!--Banner section start-->
	<div class="cy_bread_wrapper" style="background-image: url(/img/gear.jpg)!important">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">Gear</h1>
				</div>
			</div>
		</div>
	</div>
    
    <!-- shop section start -->
    <div class="pt-5" style="place-content: center;">
        <div class="container">
            <center><h3>{{title_category}}</h3></center>
            <br/>
            <p class="pr-4 pl-4" v-html="content_category"></p>
        </div>
    </div>
    <div class="cy_shop_wrapper pt-4">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="row px-2 pb-3" v-for="category in categories" :key="category.id">
                        <div class="btn-group dropright" style="width:100%;">
                            <button @click="filter_category(category.id,category.title)" type="button" class="btn btn-secondary btn-block" style="cursor:pointer;white-space: normal;">
                                {{category.title}}
                            </button>
                            <button v-if="category.child.length > 0" type="button" class="btn btn-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-expanded="false" style="cursor:pointer;">
                                <span class="sr-only">Toggle Dropright</span>
                            </button>
                            <div class="dropdown-menu">
                                <button v-for="child in category.child" @click="filter_category(child.id,child.title)" :key="child.id" style="cursor:pointer;" class="dropdown-item" href="#">{{child.title}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="cy_shop_page" id="products">
                        
                        <div class="row">
                            <div class="col-lg-4 col-md-4 item" v-for="(pro, index) in products" :key="index">
                                <div class="cy_store_box">
                                    <div class="cy_store_img">
                                        <router-link :to="'/gear/'+pro.ProductID">
                                            <v-lazy-image :src="pro.images != null ? pro.images : 'http://via.placeholder.com/370x300'"
                                            style="object-fit: cover;height:250px;width:100%" alt="store" class="img-fluid"/>
                                        </router-link>
                                        <div class="cy_store_img_overlay">
                                            <p>Sale</p>
                                        </div>
                                    </div>
                                    <div class="cy_store_data">
                                        <div class="cy_store_text">
                                            <h3><router-link style="display:inline-block;width: 100%;" router-link :to="'/gear/'+pro.ProductID">{{ pro.ProductName }}</router-link></h3>
                                        </div>
                                        <div class="mt-3 mb-3" v-html="pro.TeaserText"></div>
                                        <router-link router-link :to="'/gear/'+pro.ProductID" class="cy_button" data-hover="Go to detail"><span>Read More</span></router-link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home-gear',
    data(){
        return{
            products: [],
            mute:false,
			language_strings : [],
			reslang: [],
			base: 'https://www.admin.tourdeice.bike',
            gear:{
                content1:''
            },
            categories: [
                {
                    id: '',
                    title: '',
                    child: [
                        {
                            id:'',
                            title:''
                        }
                    ]
                }
            ],
            title_category: '',
            content_category: '',
        }
    },
	created(){
		
    },

    mounted() {
        window.scrollTo(0, 0)
		if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "en")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "en")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

        this.getData(553)
        this.getDataNode(487, 'gear')
        this.getcategoryproduct()
    },

    methods: {
        async getData(category_id = null) {
			window.scrollTo(0, 0)

            let add_category = ''

            if(category_id != null){
                add_category = '&category_id='+category_id
            }

            this.mute = true
			this.axios.get("product/getclassificationmultiple?id=1,2"+add_category)
            .then((response) => {
                this.products = response.data.data
                this.title_category = response.data.title_category
                this.content_category = response.data.content_category
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
            })
		},

        async getcategoryproduct() {
			this.axios.get("product/get_category_product")
            .then((response) => {
                this.categories = response.data.data.data
            })
            .catch((error) => {
                console.log(error)
            })
		},

        filter_category(id){
            this.getData(id)
        },

        getDataNode(id, param) {
            var dataNode = this.$store.dispatch("getNodeById", id)

            dataNode.then(response => {
                this.$data[param] = response.data
            }).catch((error) => {
                console.log(error, 'Got Nothing for this user except bad news')
            })
        },
    },
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>